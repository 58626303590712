<template>
    <console-content max-width="calc(100% - 16px)">
        <v-row align="center">
            <v-col cols="auto" class="headline">메타태그</v-col>
            <v-spacer />
            <v-col cols="auto">
                <metatag-form @input="updateItem">
                    <template #activator="{ attrs, on }">
                        <v-btn icon tile v-bind="attrs" v-on="on">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </metatag-form>
                <metatag-variables-form>
                    <template #activator="{ attrs, on }">
                        <v-btn icon tile color="primary" v-bind="{ ...attrs, loading }" v-on="on">
                            <v-icon>mdi-variable-box</v-icon>
                        </v-btn>
                    </template>
                </metatag-variables-form>
            </v-col>
        </v-row>

        <v-divider class="my-3" />

        <v-data-table v-bind="{ headers, items, loading }" hide-default-footer disable-sort disable-pagination class="elevation-1">
            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

            <template #[`item.code`]="{ value }">
                <code class="pa-0 text-pre-line transparent">{{ value }}</code>
            </template>

            <template #[`item.uses`]="{ item }">
                <v-switch v-model="item.uses" inset color="primary" hide-details class="d-inline-block my-0 ml-0 mr-n2 pa-0" @change="(uses) => update({ _id: item._id, uses })" />
            </template>

            <template #[`item.update`]="{ item }">
                <metatag-form :value="item" @input="updateItem">
                    <template #activator="{ attrs, on }">
                        <v-btn icon tile width="48" min-height="48" height="100%" v-bind="attrs" v-on="on">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </metatag-form>
            </template>

            <template #[`item.delete`]="{ item }">
                <v-btn icon tile width="48" min-height="48" height="100%" color="red" @click="remove(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>

            <template #footer>
                <v-divider />
                <v-pagination
                    v-bind="{
                        value: page,
                        length: pageCount,
                        totalVisible: 11,
                    }"
                    color="primary"
                    class="my-1"
                    @input="(page) => $router.push({ query: { ...$route.query, page } })"
                />
            </template>
        </v-data-table>

        <v-overlay v-model="loading" light color="white" class="d-flex justify-center align-center">
            <v-progress-circular size="100" width="5" color="primary" indeterminate />
        </v-overlay>
    </console-content>
</template>

<script>
import api from "@/api";

import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import MetatagForm from "@/components/console/common/metatag/metatag-form.vue";
import MetatagVariablesForm from "@/components/console/common/metatag/metatag-variables-form.vue";

const searchKeys = [{ title: "경로", value: "path" }];

export default {
    components: {
        ConsoleContent,
        MetatagForm,
        MetatagVariablesForm,
    },
    setup: () => ({
        searchKeys,
    }),
    data: () => ({
        metatags: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        headers() {
            return [
                { value: "path", text: "경로", width: 320 },
                { value: "code", text: "코드" },
                { value: "uses", text: "사용여부", width: +80, align: "center" },
                { value: "update", text: "수정", width: +48, align: "center", class: "pa-0", cellClass: "pa-0" },
                { value: "delete", text: "삭제", width: +48, align: "center", class: "pa-0", cellClass: "pa-0" },
            ];
        },
        items() {
            return this.metatags.map((item, index) => ({ ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.metatags = [];
            this.summary = { totalCount: 0 };
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, metatags } = await api.console.common.metatags.gets({
                    headers: { skip, limit },
                    params,
                });

                this.metatags = metatags;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.common.metatags.put(item);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.metatags.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.metatags.splice(index, 1, item);
            else {
                this.metatags = [item, ...this.metatags];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(item) {
            if (!confirm("해당 항목을 삭제하시겠습니까?")) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.common.metatags.delete(item);
                this.loading = false;
                await this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(.line-height-1-5) {
    line-height: 1.5;
}
:deep(.max-width-0) {
    max-width: 0;
}
:deep(.white-space-pre-line) {
    white-space: pre-line;
}
:deep(.cursor-pointer) {
    cursor: pointer;
}
:deep(.v-small-dialog__activator) {
    display: flex;
    align-items: center;
    height: 100%;
}
:deep(.v-small-dialog__activator__content) {
    width: 100%;
}
:deep(.v-data-table__th) {
    white-space: pre-line;
}
:deep(.v-data-table__expanded__content) {
    box-shadow: none !important;
}
:deep(.vertical-align-top) {
    vertical-align: top !important;
}
:deep(.v-data-table__td > span.text-truncate) {
    display: inline-block;
    width: inherit;
}
</style>
