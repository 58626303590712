<template>
    <v-row no-gutters>
        <v-col cols="12" sm="auto" align-self="center">
            <v-btn icon tile variant="text" elevation="0" size="small" class="handle cursor-grab">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="auto">
            <v-divider class="d-block d-sm-none" />
            <v-divider vertical style="width: 1px" class="d-none d-sm-block fill-height" />
        </v-col>
        <v-col cols="12" sm="3">
            <v-combobox v-model="form.key" label="key" :items="items_key" flat dense filled hide-details persistent-placeholder @input="emit" />
        </v-col>
        <v-col cols="12" sm="auto">
            <v-divider class="d-block d-sm-none" />
            <v-divider vertical style="width: 1px" class="d-none d-sm-block fill-height" />
        </v-col>
        <v-col cols="12" sm="">
            <template v-if="form.key == 'slot'">
                <v-textarea v-model="form.value" label="value" flat dense filled hide-details persistent-placeholder @input="emit" />
            </template>
            <template v-else>
                <v-combobox v-model="form.value" label="value" :items="items_value" flat dense filled hide-details persistent-placeholder @input="emit" />
            </template>
        </v-col>
        <v-col cols="12" sm="auto">
            <v-divider class="d-block d-sm-none" />
            <v-divider vertical style="width: 1px" class="d-none d-sm-block fill-height" />
        </v-col>
        <v-col sm="auto">
            <v-btn icon tile width="48" min-height="48" height="100%" color="red" @click="$emit('pull', value)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" v-if="!hideDivider">
            <v-divider />
        </v-col>
    </v-row>
</template>

<script>
import { COMMON_METATAG_TAG_TYPES, initCommonMetatag_Tag_Data } from "@/store/metatags";

export default {
    props: {
        /** @type {import("vue").Prop<ReturnType<initCommonMetatag_Tag_Data>>} */
        value: { type: Object, default: initCommonMetatag_Tag_Data },

        type: { type: String },
        keys: { type: Array },

        /** @type {import("vue").Prop<ReturnType<initCommonMetatag_Tag_Data>[]>} */
        data: { type: Array },

        hideDivider: { type: Boolean, default: false },
    },
    data: () => ({
        form: initCommonMetatag_Tag_Data(),
    }),
    computed: {
        others() {
            return this.data.filter(({ tempId }) => tempId != this.form.tempId);
        },
        items_key() {
            return [...this.keys.filter((key) => !this.others.some((item) => item.key == key)), "slot"].sort();
        },
        items_value() {
            return COMMON_METATAG_TAG_TYPES[this.type]?.data?.[this.form.key] || [];
        },
    },
    methods: {
        sync() {
            this.form = initCommonMetatag_Tag_Data(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
