<template>
    <v-row no-gutters>
        <v-col cols="auto">
            <v-btn icon tile variant="text" elevation="0" size="small" class="handle cursor-grab">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </v-col>
        <v-divider vertical />
        <v-col cols="4">
            <v-text-field v-model="form.code" label="변수명" flat dense filled hide-details persistent-placeholder @input="emit" />
        </v-col>
        <v-divider vertical />
        <v-col cols="">
            <v-text-field v-model="form.text" label="변수값" flat dense filled hide-details persistent-placeholder @input="emit" />
        </v-col>
        <v-divider vertical />
        <v-col cols="auto">
            <v-btn icon tile width="48" min-height="48" height="100%" color="red" @click="$emit('pull', value)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" v-if="showsDivider">
            <v-divider />
        </v-col>
    </v-row>
</template>

<script>
import { initCommonMetatagVariable } from "@/store/metatags";

export default {
    props: {
        /** @type {import("vue").Prop<import("@/store/metatags").CommonMetatagVariable>} */
        value: { type: Object, default: initCommonMetatagVariable },
        showsDivider: { type: Boolean, default: false },
    },
    data: () => ({
        /** @type {import("vue").Ref<import("@/store/metatags").CommonMetatagVariable>} */
        form: initCommonMetatagVariable(),
    }),
    methods: {
        sync() {
            this.form = initCommonMetatagVariable(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
