var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "hide-divider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          staticClass: "my-0 ml-0 mr-n3 pa-0",
          attrs: {
            "inset": "",
            "hide-details": ""
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.shows,
            callback: function ($$v) {
              _vm.shows = $$v;
            },
            expression: "shows"
          }
        }, 'v-switch', {
          loading: _vm.loading,
          color: _vm.switchColor
        }, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', Object.assign({}, _vm.$attrs, {
    title: _vm.title,
    loading: _vm.loading,
    actionCols: _vm.actionCols
  }), false), [_vm.$slots.length ? [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.value,
      expression: "value"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_vm._t("default")], 2)], 1)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }