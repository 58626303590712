var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "1280",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "py-5"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "경로",
      "outlined": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.form.path,
      callback: function ($$v) {
        _vm.$set(_vm.form, "path", $$v);
      },
      expression: "form.path"
    }
  }, 'v-text-field', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('view-switch-section', _vm._b({
    attrs: {
      "title": "사용여부"
    },
    model: {
      value: _vm.form.uses,
      callback: function ($$v) {
        _vm.$set(_vm.form, "uses", $$v);
      },
      expression: "form.uses"
    }
  }, 'view-switch-section', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('form-tags', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.tags,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tags", $$v);
      },
      expression: "form.tags"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('view-section-card', {
    staticClass: "fill-height",
    attrs: {
      "title": "미리보기"
    }
  }, [_c('v-card-text', [_c('code', {
    staticClass: "pa-0 text-pre-line transparent"
  }, [_vm._v(_vm._s(_vm.form.code))])])], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }