var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "handle cursor-grab",
    attrs: {
      "icon": "",
      "tile": "",
      "variant": "text",
      "elevation": "0",
      "size": "small"
    }
  }, [_c('v-icon', [_vm._v("mdi-drag")])], 1)], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "변수명",
      "flat": "",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "변수값",
      "flat": "",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.text,
      callback: function ($$v) {
        _vm.$set(_vm.form, "text", $$v);
      },
      expression: "form.text"
    }
  })], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "tile": "",
      "width": "48",
      "min-height": "48",
      "height": "100%",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('pull', _vm.value);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1), _vm.showsDivider ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }