var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.type`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-combobox', {
          attrs: {
            "items": _vm.types,
            "solo": "",
            "dense": "",
            "hide-details": "",
            "flat": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: `item.data`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('tags-data', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        })];
      }
    }, {
      key: `item.pull`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "tile": "",
            "width": "48",
            "min-height": "48",
            "height": "100%",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.pull(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "justify-end",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('v-btn', {
          attrs: {
            "icon": "",
            "tile": "",
            "width": "48",
            "min-height": "48",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.push(_vm.item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    itemsPerPage: -1
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }