<template>
    <v-data-table
        v-bind="{
            headers,
            items,
            itemsPerPage: -1,
        }"
        hide-default-footer
        disable-sort
        disable-pagination
        class="v-sheet--outlined"
    >
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.type`]="{ item }">
            <v-combobox v-model="item.type" :items="types" solo dense hide-details flat @input="emit" />
        </template>
        <template #[`item.data`]="{ item }">
            <tags-data :value="item" @input="updateItem" />
        </template>
        <template #[`item.pull`]="{ item }">
            <v-btn icon tile width="48" min-height="48" height="100%" color="red" @click="pull(item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>

        <template #footer>
            <v-divider />
            <v-row no-gutters class="justify-end">
                <v-divider vertical />
                <v-btn icon tile width="48" min-height="48" color="primary" @click="push(item)">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { initCommonMetatag_Tag, COMMON_METATAG_TAG_TYPES } from "@/store/metatags";

import TagsData from "./tags/tags-data.vue";

const types = Object.keys(COMMON_METATAG_TAG_TYPES);

export default {
    components: {
        TagsData,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    setup: () => ({
        types,
    }),
    data: () => ({
        items: [],
    }),
    computed: {
        headers() {
            return [
                { value: "type", text: "유형", width: 160, cellClass: "pa-0" },
                { value: "data", text: "내용", cellClass: "pa-0" },
                { value: "pull", width: +48, class: "pa-0", cellClass: "pa-0" },
            ];
        },
    },
    methods: {
        sync() {
            this.items = [...this.value].map(initCommonMetatag_Tag);
        },
        emit() {
            this.$emit("input", this.items);
        },
        updateItem(item) {
            let index = this.items.findIndex(({ tempId }) => tempId == item.tempId);
            if (-1 < index) {
                this.items.splice(index, 1, item);
            } else {
                this.items.push(item);
            }
            this.emit();
        },
        pull(item) {
            let index = this.items.findIndex(({ tempId }) => tempId == item.tempId);
            if (-1 < index) this.items.splice(index, 1);
            this.emit();
        },
        push() {
            this.items.push(
                initCommonMetatag_Tag({
                    type: COMMON_METATAG_TAG_TYPES.meta.value,
                })
            );
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
:deep(.line-height-1-5) {
    line-height: 1.5;
}
:deep(.max-width-0) {
    max-width: 0;
}
:deep(.white-space-pre-line) {
    white-space: pre-line;
}
:deep(.cursor-pointer) {
    cursor: pointer;
}
:deep(.v-pagination button) {
    box-shadow: none !important;
    border: thin solid rgba(0, 0, 0, 0.12);
}
:deep(.v-small-dialog__activator) {
    display: flex;
    align-items: center;
    height: 100%;
}
:deep(.v-small-dialog__activator__content) {
    width: 100%;
}
:deep(th:not(:last-of-type)),
:deep(td:not(:last-of-type)) {
    border-right: thin solid rgba(0, 0, 0, 0.12);
}
:deep(.v-data-table__th) {
    white-space: pre-line;
}
:deep(.v-data-table__expanded__content) {
    box-shadow: none !important;
}
:deep(.vertical-align-top) {
    vertical-align: top !important;
}
:deep(.v-data-table__td > span.text-truncate) {
    display: inline-block;
    width: inherit;
}
</style>
