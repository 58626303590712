var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('draggable', {
    attrs: {
      "group": _vm.form.tempId,
      "handle": ".handle"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.data,
      callback: function ($$v) {
        _vm.$set(_vm.form, "data", $$v);
      },
      expression: "form.data"
    }
  }, [_vm._l(_vm.form.data, function (item, index) {
    return [_c('tags-data-item', _vm._g(_vm._b({
      key: item.tempId,
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.form.data[index],
        callback: function ($$v) {
          _vm.$set(_vm.form.data, index, $$v);
        },
        expression: "form.data[index]"
      }
    }, 'tags-data-item', {
      type: _vm.type,
      keys: _vm.keys,
      data: _vm.form.data,
      hideDivider: index == _vm.form.data.length - 1
    }, false), {
      pull: _vm.pull
    }))];
  })], 2), _c('v-divider'), _c('v-row', {
    staticClass: "justify-end",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "tile": "",
      "width": "48",
      "min-height": "48",
      "color": "primary"
    },
    on: {
      "click": _vm.push
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }