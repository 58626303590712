var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('draggable', {
    attrs: {
      "handle": ".handle"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.variables,
      callback: function ($$v) {
        _vm.variables = $$v;
      },
      expression: "variables"
    }
  }, [_vm._l(_vm.variables, function (item, index) {
    return [_c('variables-item', _vm._g(_vm._b({
      key: item.tempId,
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.variables[index],
        callback: function ($$v) {
          _vm.$set(_vm.variables, index, $$v);
        },
        expression: "variables[index]"
      }
    }, 'variables-item', {
      showsDivider: index < _vm.variables.length - 1
    }, false), {
      pull: _vm.pull
    }))];
  })], 2)], 1), !_vm.variables.length ? [_c('v-col', {
    staticClass: "px-4 text-caption d-flex flex-column justify-center align-center",
    staticStyle: {
      "height": "40px"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(" 추가된 항목이 없습니다 ")])])] : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-btn', {
    attrs: {
      "icon": "",
      "tile": "",
      "width": "48",
      "min-height": "48",
      "color": "primary"
    },
    on: {
      "click": _vm.push
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }