<template>
    <view-section-card v-bind="{ ...$attrs, title, loading, actionCols }" hide-divider>
        <template #actions>
            <v-switch v-model="shows" v-bind="{ loading, color: switchColor }" inset hide-details class="my-0 ml-0 mr-n3 pa-0" @change="emit" />
        </template>
        <template v-if="$slots.length">
            <v-expand-transition>
                <v-sheet v-show="value">
                    <v-divider />
                    <v-card-text>
                        <slot />
                    </v-card-text>
                </v-sheet>
            </v-expand-transition>
        </template>
    </view-section-card>
</template>

<script>
import ViewSectionCard from "./view-section-card.vue";
import ViewSectionTitle from "./view-section-title.vue";

export default {
    components: {
        ViewSectionCard,
        ViewSectionTitle,
    },
    props: {
        value: { type: Boolean, default: false },

        title: { type: String },
        actionCols: { type: [String, Number], default: "auto" },

        loading: { type: Boolean, default: false },

        switchColor: { type: String, default: "primary" },
    },
    data: () => ({
        shows: false,
    }),
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
