<template>
    <v-card outlined>
        <v-row no-gutters>
            <v-col cols="12">
                <draggable v-model="variables" handle=".handle" @input="emit">
                    <template v-for="(item, index) in variables">
                        <variables-item
                            v-model="variables[index]"
                            v-bind="{
                                showsDivider: index < variables.length - 1,
                            }"
                            v-on="{
                                pull,
                            }"
                            :key="item.tempId"
                            @input="emit"
                        />
                    </template>
                </draggable>
            </v-col>
            <template v-if="!variables.length">
                <v-col cols="12" class="px-4 text-caption d-flex flex-column justify-center align-center" style="height: 40px">
                    <span> 추가된 항목이 없습니다 </span>
                </v-col>
            </template>
            <v-col cols="12"> <v-divider /> </v-col>
            <v-col cols="12">
                <v-row no-gutters>
                    <v-spacer />
                    <v-divider vertical />

                    <v-btn icon tile width="48" min-height="48" color="primary" @click="push">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { initCommonMetatagVariable } from "@/store/metatags";

import Draggable from "vuedraggable";
import VariablesItem from "./variables-item.vue";

export default {
    components: {
        Draggable,
        VariablesItem,
    },
    props: {
        /** @type {import("vue").Prop<import("@/store/metatags").CommonMetatagVariable[]>} */
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        /** @type {import("vue").Ref<import("@/store/metatags").CommonMetatagVariable[]>} */
        variables: [],
    }),
    methods: {
        sync() {
            this.variables = [...this.value].map(initCommonMetatagVariable);
        },
        emit() {
            this.$emit("input", this.variables);
        },
        push() {
            this.variables.push(initCommonMetatagVariable());
            this.emit();
        },
        pull(item) {
            let index = this.variables.findIndex(({ tempId }) => tempId == item.tempId);
            if (-1 < index) this.variables.splice(index, 1);
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
