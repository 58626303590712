var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "calc(100% - 16px)"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("메타태그")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('metatag-form', {
    on: {
      "input": _vm.updateItem
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }])
  }), _c('metatag-variables-form', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "tile": "",
            "color": "primary"
          }
        }, 'v-btn', Object.assign({}, attrs, {
          loading: _vm.loading
        }), false), on), [_c('v-icon', [_vm._v("mdi-variable-box")])], 1)];
      }
    }])
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-data-table', _vm._b({
    staticClass: "elevation-1",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref3) {
          var value = _ref3.value,
            item = _ref3.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.code`,
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_c('code', {
          staticClass: "pa-0 text-pre-line transparent"
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: `item.uses`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-switch', {
          staticClass: "d-inline-block my-0 ml-0 mr-n2 pa-0",
          attrs: {
            "inset": "",
            "color": "primary",
            "hide-details": ""
          },
          on: {
            "change": function (uses) {
              return _vm.update({
                _id: item._id,
                uses
              });
            }
          },
          model: {
            value: item.uses,
            callback: function ($$v) {
              _vm.$set(item, "uses", $$v);
            },
            expression: "item.uses"
          }
        })];
      }
    }, {
      key: `item.update`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('metatag-form', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var attrs = _ref7.attrs,
                on = _ref7.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "tile": "",
                  "width": "48",
                  "min-height": "48",
                  "height": "100%"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: `item.delete`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "tile": "",
            "width": "48",
            "min-height": "48",
            "height": "100%",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', _vm._b({
          staticClass: "my-1",
          attrs: {
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        }, 'v-pagination', {
          value: _vm.page,
          length: _vm.pageCount,
          totalVisible: 11
        }, false))];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-overlay', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "light": "",
      "color": "white"
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "5",
      "color": "primary",
      "indeterminate": ""
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }