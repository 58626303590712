<template>
    <div>
        <draggable v-model="form.data" :group="form.tempId" handle=".handle" @input="emit">
            <template v-for="(item, index) in form.data">
                <tags-data-item
                    v-model="form.data[index]"
                    v-bind="{
                        type,
                        keys,
                        data: form.data,
                        hideDivider: index == form.data.length - 1,
                    }"
                    v-on="{
                        pull,
                    }"
                    :key="item.tempId"
                    @input="emit"
                />
            </template>
        </draggable>
        <v-divider />
        <v-row no-gutters class="justify-end">
            <v-col cols="auto">
                <v-divider vertical />
            </v-col>
            <v-col cols="auto">
                <v-btn icon tile width="48" min-height="48" color="primary" @click="push">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { initCommonMetatag_Tag, initCommonMetatag_Tag_Data, COMMON_METATAG_TAG_TYPES } from "@/store/metatags";

import Draggable from "vuedraggable";
import TagsDataItem from "./tags-data-item.vue";

export default {
    components: {
        Draggable,
        TagsDataItem,
    },
    props: {
        /** @type {import("vue").Prop<ReturnType<initCommonMetatag_Tag>>} */
        value: { type: Object, default: initCommonMetatag_Tag },
    },
    data: () => ({
        form: initCommonMetatag_Tag(),
    }),
    computed: {
        type() {
            return this.value?.type;
        },
        keys() {
            return Object.keys(COMMON_METATAG_TAG_TYPES[this.type]?.data || {});
        },
    },
    methods: {
        sync() {
            this.form = initCommonMetatag_Tag(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        push() {
            this.form.data.push(initCommonMetatag_Tag_Data());
            this.emit();
        },
        pull(item) {
            let index = this.form.data.findIndex(({ tempId }) => tempId == item.tempId);
            if (-1 < index) this.form.data.splice(index, 1);
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
