var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto",
      "align-self": "center"
    }
  }, [_c('v-btn', {
    staticClass: "handle cursor-grab",
    attrs: {
      "icon": "",
      "tile": "",
      "variant": "text",
      "elevation": "0",
      "size": "small"
    }
  }, [_c('v-icon', [_vm._v("mdi-drag")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-block d-sm-none"
  }), _c('v-divider', {
    staticClass: "d-none d-sm-block fill-height",
    staticStyle: {
      "width": "1px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-combobox', {
    attrs: {
      "label": "key",
      "items": _vm.items_key,
      "flat": "",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.key,
      callback: function ($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-block d-sm-none"
  }), _c('v-divider', {
    staticClass: "d-none d-sm-block fill-height",
    staticStyle: {
      "width": "1px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_vm.form.key == 'slot' ? [_c('v-textarea', {
    attrs: {
      "label": "value",
      "flat": "",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })] : [_c('v-combobox', {
    attrs: {
      "label": "value",
      "items": _vm.items_value,
      "flat": "",
      "dense": "",
      "filled": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })]], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-block d-sm-none"
  }), _c('v-divider', {
    staticClass: "d-none d-sm-block fill-height",
    staticStyle: {
      "width": "1px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "tile": "",
      "width": "48",
      "min-height": "48",
      "height": "100%",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('pull', _vm.value);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1), !_vm.hideDivider ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }