<template>
    <v-dialog v-model="shows" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title>
                {{ title }}
                <v-btn absolute right icon tile @click="shows = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="py-5">
                <variables-list v-model="variables" @input="emit" />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="pink" @click="undo">초기화</v-btn>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { initCommonMetatagVariable } from "@/store/metatags";

import VariablesList from "./variables/variables-list.vue";

export default {
    components: {
        VariablesList,
    },
    data: () => ({
        variables: [],

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapState("metatags", { variables_original: "variables" }),
        title() {
            let title = "메타태그 변수 설정";
            return title;
        },
    },
    methods: {
        ...mapActions("metatags", ["getVariables", "setVariables"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                await this.getVariables();
                this.undo();
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await this.setVariables(this.variables);

                alert("설정이 저장되었습니다");

                this.shows = false;
            } finally {
                this.loading = false;
            }
        },

        undo() {
            this.variables = [...this.variables_original].map(initCommonMetatagVariable);
            this.emit();
        },

        emit() {
            this.variables = [...this.variables].map(initCommonMetatagVariable);
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
};
</script>
