<template>
    <v-dialog v-model="shows" width="1280" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title>
                {{ title }}
                <v-btn absolute right icon tile @click="shows = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.path" label="경로" outlined hide-details persistent-placeholder v-bind="{ loading }" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <view-switch-section v-model="form.uses" title="사용여부" v-bind="{ loading }" />
                    </v-col>
                    <v-col cols="12" lg="8" class="pa-2">
                        <form-tags v-model="form.tags" @input="emit" />
                    </v-col>
                    <v-col cols="12" lg="4" class="pa-2">
                        <view-section-card title="미리보기" class="fill-height">
                            <v-card-text>
                                <code class="pa-0 text-pre-line transparent">{{ form.code }}</code>
                            </v-card-text>
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" elevation="0" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { initCommonMetatag } from "@/store/metatags";

import api from "@/api";

import ViewSectionCard from "@/components/console/dumb/view/view-section-card.vue";
import ViewSwitchSection from "@/components/console/dumb/view/view-switch-section.vue";
import FormTags from "./form/form-tags.vue";

export default {
    components: {
        ViewSectionCard,
        ViewSwitchSection,
        FormTags,
    },
    props: {
        value: { type: Object, default: initCommonMetatag },
    },
    data: () => ({
        form: initCommonMetatag(),

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapGetters("metatags", ["variables_object"]),

        isCreate() {
            return !this.value?._id;
        },
        title() {
            let title = "메타태그";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    methods: {
        ...mapActions("metatags", ["getVariables"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                await this.getVariables();

                let { variables_object } = this;
                if (this.isCreate) {
                    this.form = initCommonMetatag.bind({ variables_object })();
                } else {
                    let { metatag } = await api.console.common.metatags.get({ _id: this.value?._id });
                    this.form = initCommonMetatag.bind({ variables_object })(metatag);
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { path } = this.form;

                if (!path) throw new Error("경로를 입력하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.common.metatags;
                let { ...form } = this.form;

                let { metatag } = await (this.isCreate ? post : put)(form);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", metatag);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
        emit() {
            let { variables_object } = this;
            this.form = initCommonMetatag.bind({ variables_object })(this.form);
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
};
</script>
